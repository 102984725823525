import React, { useState, useEffect, lazy } from "react";
import { ToastContainer, toast } from "react-toastify";
import ProductService from "../services/ProductService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEye,
  faEyeSlash,
  faRecycle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import UpdateImagesVariantPopup from "./ProductPopupModel/updateImagesVariantPopup";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import urlsCommon from "src/common/urls-common";
import VariantsService from "src/services/VariantsService";
import UpdateOrderImagesVariant from "./ProductPopupModel/updateOrderImagesVariant";
import Circle from "src/common/circle";
import ShowVariantStock from "./ProductPopupModel/showVariantStock";
import SelectOptionsService from "src/services/SelectOptionsService";

function DetailsProduct() {
  const animatedComponents = makeAnimated();
  const animatedComponentstwo = makeAnimated();
  const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
  const [isModalOpenVariantEdit, setIsModalOpenVariantEdit] = useState(false);
  const [isModalOpenShowStock, setIsModalOpenShowStock] = useState(false);
  const [
    isModalOpenEditOrderImagesVariant,
    setIsModalOpenEditOrderImagesVariant,
  ] = useState(false);
  const [sizesVariantStock, setSizesVariantStock] = useState([]); //show stock
  const [product, setProduct] = useState("");
  const [category, setCategory] = useState([]);
  const [index, setIndex] = useState("");
  const [isSaved, setIsSaved] = useState(false);
  const [variantSelectedImagesOrder, setVariantSelectedImagesOrder] =
    useState("");
  //edit product
  const [productUpdate, setProductUpdate] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  //similaires produits
  const [optionsProducts, setOptionsProducts] = useState([]);
  const [selectedSimilaires, setSelectedSimilaires] = useState([]);
  const [selectedLook, setSelectedLook] = useState([]);

  useEffect(() => {
    getProductDetailsById(localStorage.getItem("isProductDetails"));
    getAllCategoriesAndSousFamilles();
    getAllProductsForSelectSimilaires();
    getSimilarProductsforProduct(localStorage.getItem("isProductDetails"));
    getIdeeLookProductsforProduct(localStorage.getItem("isProductDetails"));
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setProductUpdate({ ...productUpdate, [name]: value });
  };
  const openModalVariantEdit = (index) => {
    setIsModalOpenVariantEdit(true);
    setIndex(index);
    setIsSaved(false);
  };
  const openModalShowStock = (sizesVariant) => {
    setIsModalOpenShowStock(true);
    setSizesVariantStock(sizesVariant);
    setIsSaved(false);
  };

  const openModalEditOrderImagesVariant = (variantSelected) => {
    setIsModalOpenEditOrderImagesVariant(true);
    setVariantSelectedImagesOrder(variantSelected);
    setIsSaved(false);
  };
  const updateProduct = (id) => {
    const model = {
      id: id,
      nameToFo: productUpdate?.nameToFo || product?.nameToFo,
      category: selectedCategory || product?.category?.id,
      description: productUpdate?.description || product?.description,
      similarProductIds: selectedSimilaires.map((product) => product.value),
      lookProductIds: selectedLook.map((product) => product.value),
    };
    console.log("model :", model);
    ProductService.updateProduct(model)
      .then((response) => {
        if (response.data.success) {
          console.log("model?.id :" + model?.id);
          getProductDetailsById(model?.id);
          toast.success(response.data.success);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((e) => {
        toast.error(e);
      });
  };
  const getProductDetailsById = (idProducts) => {
    ProductService.getProductDetailsById(idProducts)
      .then((response) => {
        if (response.data.success) {
          setProduct(response.data.success);
        }
        console.log("product", response.data.success);
      })
      .catch((e) => {
        // setOpenErrorAlert(true);
        // setErrorAlertText(e);
      });
  };
  const getAllCategoriesAndSousFamilles = () => {
    SelectOptionsService.getAllCategoriesAndSousFamilles()
      .then((response) => {
        if (response.data.success) {
          setCategory(response.data.success);
          console.log("response.data.success :", response.data.success);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  /*******
   * ****produits similaires et Look functions
   * ********/

  const handleSimilairesChange = (selectedOptions) => {
    setSelectedSimilaires(selectedOptions);
  };
  const handleLookChange = (selectedOptions) => {
    setSelectedLook(selectedOptions);
  };
  const getAllProductsForSelectSimilaires = () => {
    ProductService.getAllProductsForSimilarProductsOrIdeeLook()
      .then((response) => {
        if (response.data.success) {
          setOptionsProducts(response.data.success);
        }
      })
      .catch((e) => {
        // setOpenErrorAlert(true);
        // setErrorAlertText(e);
      });
  };
  const getSimilarProductsforProduct = (idProducts) => {
    ProductService.getSimilarProductsforProduct(idProducts)
      .then((response) => {
        if (response.data.success) {
          setSelectedSimilaires(response.data.success);
        }
      })
      .catch((e) => {
        // setOpenErrorAlert(true);
        // setErrorAlertText(e);
      });
  };
  const getIdeeLookProductsforProduct = (idProducts) => {
    ProductService.getIdeeLookProductsforProduct(idProducts)
      .then((response) => {
        if (response.data.success) {
          setSelectedLook(response.data.success);
        }
        console.log("Les produits similaires ", response.data.success);
      })
      .catch((e) => {
        // setOpenErrorAlert(true);
        // setErrorAlertText(e);
      });
  };
  /***************
   * ******sections Edit ***
   * ***/
  const detailsProductsSimilairesAndLookEdit = () => {
    return (
      <>
        <div>
          <h5 className="my-3">
            <label className="mr-2">Les produits Similaires :</label>
            <Select
              closeMenuOnSelect={false}
              components={animatedComponents}
              isMulti
              options={optionsProducts}
              onChange={handleSimilairesChange}
              value={selectedSimilaires}
            />
          </h5>
          {/* {selectedSimilaires.length > 0 && (
            <div className="bg-warning py-2 px-3 mt-4">
              <h2 className="mb-0">Les produits Similaires</h2>
              {selectedSimilaires.map((item, index) => (
                <ul className="mt-3" key={index}>
                  <li>{item?.label || ""}</li>
                </ul>
              ))}
            </div>
          )} */}

          <h5 className="my-3">
            <label className="mr-2">Idée Look Produit</label>
            <Select
              closeMenuOnSelect={false}
              components={animatedComponentstwo}
              isMulti
              options={optionsProducts}
              onChange={handleLookChange}
              value={selectedLook}
            />
          </h5>
          {/* {selectedLook.length > 0 && (
            <div className="bg-warning py-2 px-3 mt-4">
              <h2 className="mb-0">Les produits Idée Look</h2>
              {selectedLook.map((item, index) => (
                <ul className="mt-3" key={index}>
                  <li>{item?.label || ""}</li>
                </ul>
              ))}
            </div>
          )} */}
        </div>
      </>
    );
  };
  const detailsProductsSectionEdit = () => {
    return (
      <>
        <div className="card-body">
          <div className="row">
            {detailsProductsPictureSection()}
            <div className="col-12 col-sm-6">
              <h4>
                {product?.codeArticle && (
                  <span class="right badge badge-success">
                    {product?.codeArticle}
                  </span>
                )}
              </h4>
              <br />

              <select
                className="form-control"
                value={selectedCategory || product?.category}
                onChange={(e) => setSelectedCategory(e.target.value)}
              >
                {category.length > 0 &&
                  category.map((item) => (
                    <>
                      <option value={item.value}>{item.label}</option>
                    </>
                  ))}
              </select>
              <div className="mt-3"></div>
              <h5>
                <label>Libelle du Site :</label>{" "}
                <input
                  className="form-control"
                  type="text"
                  name="nameToFo"
                  value={productUpdate?.nameToFo || product?.nameToFo}
                  onChange={handleChange}
                />
              </h5>
              <h6>
                <label>Description :</label>
              </h6>
              <p>
                <textarea
                  className="form-control"
                  type="text"
                  name="description"
                  value={productUpdate?.description || product?.description}
                  onChange={handleChange}
                  rows={3}
                />
              </p>
              {/* <hr /> */}
              {/* <h4>
                <label> Les couleurs disponibles</label>
              </h4>
              <div className="btn-group btn-group-toggle" data-toggle="buttons">
                {product.variants &&
                  product.variants.map((variant, index) => (
                    <label
                      key={index}
                      className="btn btn-default text-center active"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <input
                        type="radio"
                        name="color_option"
                        id={`color_option_${index}`}
                        autoComplete="off"
                        checked
                      />
                      {variant?.colorsVariant?.colorName || ""}
                      <div className="mb-2"></div>
                      <Circle
                        colors={variant?.colorsVariant?.colorHexa || ""}
                      />
                    </label>
                  ))}
              </div>
              <div className="bg-gray py-2 px-3 mt-4">
                <h2 className="mb-0">Prix : {product?.price || ""} TND</h2>
                {product?.sold && (
                  <>
                    <h4 className="mt-0">
                      <small>
                        % Prix promotion : {product?.sale_price} TND
                      </small>
                    </h4>
                    <h4 className="mt-0">
                      <small>% Promotion : {product?.percent}</small>
                    </h4>
                  </>
                )}
              </div> */}
              {detailsProductsSimilairesAndLookEdit()}
              <br />
              <button
                className="btn  btn-secondary"
                onClick={(e) => setIsModalOpenEdit(false)}
              >
                Annuler
              </button>
              <button
                className="btn  btn-dark "
                onClick={(e) => {
                  updateProduct(product?.id);
                  setIsModalOpenEdit(false);
                }}
                style={{ float: "right" }}
              >
                Confirmer
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };
  /***************
   * ******Variants ***
   * ***/
  const archiverVariantById = (idVariant) => {
    VariantsService.archiver(idVariant)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          getProductDetailsById(product?.id);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  const visibledVariantById = (idVariant) => {
    VariantsService.visible(idVariant)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          getProductDetailsById(product?.id);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };
  const [
    isModalOpenEditDefaultVariantProductFoProduct,
    setIsModalOpenEditDefaultVariantProductFoProduct,
  ] = useState(false);
  const [selectedDefaultVariantProductFo, setSelectedDefaultVariantProductFo] =
    useState("");
  const handleEditDefaultVariantProductFo = async (e) => {
    e.preventDefault();
    if (selectedDefaultVariantProductFo != null) {
      try {
        console.log(product?.id);
        const response =
          await VariantsService.updateVariantDefaultProductToShowInFo(
            selectedDefaultVariantProductFo,
            product?.id
          );

        if (response.data.success) {
          toast.success(response.data.success);
          setIsModalOpenEditDefaultVariantProductFoProduct(false);
          getProductDetailsById(product?.id);
        } else {
          toast.error(response.data.error);
        }
      } catch (error) {
        console.error("Error editing DefaultVariantProductFo:", error);
        toast.error(
          "An error occurred while editing the DefaultVariantProductFo."
        );
      }
    } else {
      toast.error("If faut selectionner DefaultVariantProductFo");
    }
  };
  /***************
   * ******sections ***
   * ***/
  const detailsDefaultVariantsProductSection = () => {
    return (
      <>
        <div className="float-right mb-2">
          {isModalOpenEditDefaultVariantProductFoProduct ? (
            <>
              <>
                <div className="edit-category-form">
                  <select
                    className="form-control"
                    value={
                      selectedDefaultVariantProductFo ||
                      product?.idVariantDefault
                        ? product?.idVariantDefault
                        : ""
                    }
                    onChange={(e) =>
                      setSelectedDefaultVariantProductFo(e.target.value)
                    }
                  >
                    <option value="">---Pas de variant default---</option>
                    {product?.variants &&
                      product.variants
                        .filter((variant) => variant.visible)
                        .map((variant, index) => (
                          <option key={variant.id} value={variant.id}>
                            variant {index + 1} - {variant.codeABarre || ""}
                          </option>
                        ))}
                  </select>{" "}
                  <div className="float-right mt-2">
                    <button
                      className="btn btn-sm btn-warning mr-2"
                      onClick={(e) => handleEditDefaultVariantProductFo(e)}
                    >
                      Modifier
                    </button>
                    <button
                      className="btn btn-sm btn-secondary"
                      onClick={() =>
                        setIsModalOpenEditDefaultVariantProductFoProduct(false)
                      }
                    >
                      Annuler
                    </button>
                  </div>
                </div>
              </>
            </>
          ) : (
            <>
              <button
                className="btn btn-sm btn-dark"
                onClick={() =>
                  setIsModalOpenEditDefaultVariantProductFoProduct(true)
                }
              >
                {product?.idVariantDefault ? (
                  <>
                    <b> Modifier variant Default</b>
                  </>
                ) : (
                  <>
                    <b> Ajouter variant Default</b>
                  </>
                )}
              </button>
            </>
          )}
        </div>
      </>
    );
  };
  const detailsVariantsProductSection = () => {
    return (
      <>
        <div className="card card-secondary">
          <div className="card-header">
            <h3 className="card-title">
              Les variants du produit : <b>{product.nameToFo}</b>
            </h3>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="card-body">
                {detailsDefaultVariantsProductSection()}
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Couleur</th>
                      <th>Nom Couleur</th>
                      <th>Tailles</th>
                      <th>Visibilité Sur Site</th>
                      <th>Nb Images</th>
                      <th>variant default</th>
                      <th>Modifier Ordre Images</th>
                      <th>Stock</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {product?.variants &&
                      product?.variants.map((variant, index) => (
                        <tr key={index}>
                          <td>{variant?.colorsVariant?.colorHexa || ""}</td>
                          <td>{variant?.colorsVariant?.colorName || ""}</td>
                          <td>
                            {variant.sizesVariant &&
                            variant.sizesVariant.length > 0
                              ? variant.sizesVariant.map(
                                  (itemSize, indexsize) => (
                                    <React.Fragment key={indexsize}>
                                      {indexsize > 0 && ", "}
                                      {itemSize?.size || ""}
                                    </React.Fragment>
                                  )
                                )
                              : null}
                          </td>
                          <td>{variant?.visible ? "oui" : "non"}</td>
                          <td>{variant?.nbImages || 0}</td>
                          <td>{variant?.defaultToFo ? "Oui" : "Non"}</td>
                          <td>
                            <div className="text-center">
                              <button
                                className="btn btn-sm btn-dark"
                                onClick={() =>
                                  openModalEditOrderImagesVariant(variant)
                                }
                              >
                                Modifier
                              </button>
                            </div>
                          </td>
                          <td>
                            <button
                              className="btn btn-sm btn-warning"
                              onClick={() =>
                                openModalShowStock(variant?.sizesVariant)
                              }
                            >
                              voir
                            </button>
                          </td>
                          <td>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignContent: "center",
                              }}
                            >
                              <button
                                style={{ width: 40 }}
                                className={`btn btn-sm ${
                                  variant?.visible
                                    ? "btn-primary"
                                    : "btn-secondary"
                                }`}
                                onClick={() => visibledVariantById(variant?.id)}
                              >
                                <FontAwesomeIcon
                                  icon={variant?.visible ? faEye : faEyeSlash}
                                />
                              </button>
                              <div className="mr-3"></div>
                              <button
                                className="btn btn-sm btn-warning"
                                onClick={() => openModalVariantEdit(index)}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </button>
                              <div className="mr-3"></div>
                              <button
                                style={{ width: 40 }}
                                className={`btn btn-sm ${
                                  variant?.archived
                                    ? "btn-success"
                                    : "btn-danger"
                                }`}
                                onClick={() => archiverVariantById(variant?.id)}
                              >
                                <FontAwesomeIcon
                                  icon={variant?.archived ? faRecycle : faTrash}
                                />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    {isModalOpenVariantEdit && (
                      <UpdateImagesVariantPopup
                        setIsSaved={setIsSaved}
                        isModalOpenEdit={isModalOpenVariantEdit}
                        setIsModalOpenEdit={setIsModalOpenVariantEdit}
                        variantImages={product.variants[index].imagesVariant}
                        idVariant={product.variants[index].id}
                      />
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const detailsProductsPictureSection = () => {
    return (
      <>
        <div className="col-12 col-sm-6">
          <h3 className="d-inline-block d-sm-none">{product?.name || ""}</h3>
          <div className="col-12">
            <img
              src={
                (product.variants &&
                  product.variants.find((variant) => variant.defaultToFo)
                    ?.imagesVariant[0]?.urlImage) ||
                ""
              }
              style={{ width: "569px", height: "600px" }}
              className="product-image"
              alt="Product Image"
              loading="lazy"
            />
          </div>
          <div className="col-12 product-image-thumbs">
            {product.variants &&
              product.variants
                .find((variant) => variant.defaultToFo)
                ?.imagesVariant.map((imageVariant, index) => (
                  <>
                    <div className="product-image-thumb" key={index}>
                      <img
                        src={imageVariant?.urlImage || ""}
                        alt="Product Image"
                         loading="lazy"
                      />
                    </div>
                  </>
                ))}
          </div>
        </div>
      </>
    );
  };
  const detailsProductsSection = () => {
    return (
      <>
        <div className="card-body">
          <div className="row">
            {detailsProductsPictureSection()}
            <div className="col-12 col-sm-6">
              <h4>
                {product?.codeArticle && (
                  <span class="right badge badge-success">
                    {product?.codeArticle}
                  </span>
                )}
              </h4>
              <br />
              <h5>
                <>
                  <label>Category :</label>{" "}
                  {product?.category?.name || "Pas de category"}
                </>
              </h5>
              <h5>
                <>
                  <label>Sous Famille :</label>{" "}
                  {product?.sousFamille?.name || ""}
                </>
              </h5>
              <h5>
                <label>Libelle du Site :</label>{" "}
                {product?.nameToFo || "Pas de Libelle de site"}
              </h5>
              <h6>
                <label>Description :</label>
              </h6>
              <p>{product?.description || ""}</p>
              <hr />
              <h4>
                <label> Les couleurs disponibles</label>
              </h4>
              <div className="btn-group btn-group-toggle" data-toggle="buttons">
                {product.variants &&
                  product.variants.map((variant, index) => (
                    <label
                      key={index}
                      className="btn btn-default text-center active"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <input
                        type="radio"
                        name="color_option"
                        id={`color_option_${index}`}
                        autoComplete="off"
                        checked
                      />
                      {variant?.colorsVariant?.colorName || ""}
                      <div className="mb-2"></div>
                      <Circle
                        colors={variant?.colorsVariant?.colorHexa || ""}
                      />
                    </label>
                  ))}
              </div>
              <div className="bg-gray py-2 px-3 mt-4">
                <h2 className="mb-0">
                  Prix : {product?.price?.toFixed(3) || ""} TND
                </h2>
                {product?.sold && (
                  <>
                    <h4 className="mt-0">
                      <small>
                        % Prix promotion : {product?.sale_price?.toFixed(3)} TND
                      </small>
                    </h4>
                    <h4 className="mt-0">
                      <small>% Promotion : {product?.percent}</small>
                    </h4>
                  </>
                )}
              </div>
              {selectedSimilaires.length > 0 && (
                <div className="bg-warning py-2 px-3 mt-4">
                  <h2 className="mb-0">Les produits Similaires</h2>
                  {selectedSimilaires.map((item, index) => (
                    <ul className="mt-3" key={index}>
                      <li>{item?.label || ""}</li>
                    </ul>
                  ))}
                </div>
              )}
              {selectedLook.length > 0 && (
                <div className="bg-warning py-2 px-3 mt-4">
                  <h2 className="mb-0">Les produits Idée Look</h2>
                  {selectedLook.map((item, index) => (
                    <ul className="mt-3" key={index}>
                      <li>{item?.label || ""}</li>
                    </ul>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="content-wrapper">
        <ToastContainer />
        <section className="content-header">
          <div className="container-fluid">
            <div className="col-md-12">
              <div className="card card-secondary">
                <div
                  className="card-header"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <h3 className="card-title">
                    Détails du produit
                    <h4 className="my-3">{product?.name || ""}</h4>
                  </h3>
                  <div style={{ marginLeft: "auto" }}>
                    {!isModalOpenEdit && (
                      <button
                        className="btn btn-warning mr-2"
                        onClick={() => setIsModalOpenEdit(true)}
                      >
                        Modifier
                      </button>
                    )}
                    <button
                      className="btn btn-light"
                      onClick={() => {
                        window.open(
                          urlsCommon.URLS_SITE +
                            "article/" +
                            (product?.slug || ""),
                          "_blank"
                        );
                      }}
                    >
                      Voir dans le site
                    </button>
                  </div>
                </div>
                {isModalOpenEdit
                  ? detailsProductsSectionEdit()
                  : detailsProductsSection()}
              </div>
              {detailsVariantsProductSection()}
              {isModalOpenEditOrderImagesVariant && (
                <UpdateOrderImagesVariant
                  setIsSaved={setIsSaved}
                  isModalOpenEditOrderImagesVariant={
                    isModalOpenEditOrderImagesVariant
                  }
                  setIsModalOpenEditOrderImagesVariant={
                    setIsModalOpenEditOrderImagesVariant
                  }
                  variantSelected={variantSelectedImagesOrder}
                />
              )}
              {isModalOpenShowStock && (
                <ShowVariantStock
                  setIsSaved={setIsSaved}
                  setIsModalOpenShowStock={setIsModalOpenShowStock}
                  isModalOpenShowStock={isModalOpenShowStock}
                  sizesVariantStock={sizesVariantStock}
                />
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default DetailsProduct;
