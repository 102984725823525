import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import Home from "./Constant-Pages/Home";
import { createStore, applyMiddleware, compose } from "redux";
import reducers from "./reduxSaga/reducers/index";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";

export default class App extends Component {
  render() {
    const sagaMiddleware = createSagaMiddleware();

    const middleware = [sagaMiddleware];

    const store = compose(applyMiddleware(...middleware))(createStore)(
      reducers
    );

    return (
      <BrowserRouter>
        <Provider store={store}>
          <>
            <body class="hold-transition sidebar-mini layout-footer-fixed">
              <div class="wrapper">
                <div className="preloader flex-column justify-content-center align-items-center">
                  <img
                    className="animation__shake"
                    src="dist/img/logo-yupi.svg"
                    alt="AdminLTELogo"
                    width={200}
                  />
                </div>
                <Home />
                {/* <div class="content-wrapper"></div> */}
              </div>
            </body>
          </>
        </Provider>
      </BrowserRouter>
    );
  }
}
